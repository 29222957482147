import * as React from "react";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout pageTitle="Spring Onion">
      <p className="text-center italic">Experience the adventure of everyday life!</p>
      <p>Spring Onion LLC was founded in 2021 in Raleigh, North Carolina.</p>
      <p>As of January 2024, Spring Onion LLC's assets have been acquired by <a href="https://goliathonline.net/">Goliath Online</a>.</p>
    </Layout>
  );
};

export default IndexPage;
